<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>{{ this.messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>{{ this.messagetype.title_jp }}</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'Front board archive', params: { type: 'board' } }"
                >{{ messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li><span>新規投稿</span></li>
          </ul>
        </div>
        <Form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit="register"
          v-slot="{ errors }"
        >
          <div class="content">
            <div class="innar">
              <div class="form_outwrap">
                <div v-if="messagecolumn['title']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["title"] }}
                    <ErrorMessage class="each_msg" name="title" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="title"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.title }"
                      v-model="cretemessage.title"
                      :rules="isRequired"
                    />
                  </div>
                </div>
                <div v-if="messagecolumn['content']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["content"]
                    }}<ErrorMessage class="each_msg" name="content" />
                  </p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      @input="onEditorInput"
                      v-model="cretemessage.content"
                      :config="editorConfig"
                    ></ckeditor>
                    <Field
                      name="content"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      :class="{ 'is-invalid': errors.content }"
                      v-model="cretemessage.content"
                      :rules="isRequired"
                    />
                  </div>
                </div>

                <div
                  v-if="messagecolumn['photolog']"
                  class="wrap wid_100per t_l m_b20"
                >
                  <div class="camera_btn" id="post_img_area">
                    <label>
                      <input
                        type="file"
                        name="image[]"
                        class="input-file form-control post_img-file"
                        accept="image/*"
                      />
                      <!-- capture="camera" -->
                      <span>画像を選択</span>
                    </label>
                  </div>
                </div>
                <div
                  v-if="messagecolumn['photolog']"
                  id="preview"
                  class="wrap wid_100per"
                  style="display: none"
                ></div>

                <div
                  v-if="messagecolumn['attached_file']"
                  class="wrap wid_100per m_t40"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["attached_file"]
                    }}<span
                      class=""
                      style="font-size: 1.4rem; margin-left: 0.5em"
                      >※アップロードできるファイルはpdfファイルです。</span
                    >
                    <ErrorMessage class="each_msg" name="attached_file" />
                  </p>
                  <div class="in_wrap" id="attached_area">
                    <div class="attached_wrap">
                      <div class="attached_name_btn">
                        <input
                          name="rel_doc_title[]"
                          type="text"
                          class="fileName form-control"
                          required
                          placeholder="例：今月のスケジュールについて"
                        />
                      </div>
                      <div class="attached_fire_btn">
                        <label>
                          <input
                            type="file"
                            name="rel_doc[]"
                            class="input-file form-control"
                            accept=".pdf"
                          />
                          <span class="file_name">添付ファイル</span>
                        </label>
                      </div>
                      <div class="attached_action_btn">
                        <div class="add_task_link task_link_btn">
                          <i class="fas fa-plus"></i>
                        </div>
                        <div
                          class="delete_task_link task_link_btn"
                          tabindex="-1"
                          data-target="1"
                        >
                          <i class="fas fa-minus"></i>
                        </div>
                      </div>
                      <div class="fileDisplayName"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="t_c">
                <div class="btn_large">
                  <button
                    :disabled="processing"
                    type="submit"
                    class="btn btn-primary"
                  >
                    送信
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
  <template id="post_img_btn">
    <label>
      <input
        type="file"
        name="image[]"
        class="input-file form-control post_img-file"
        accept="image/*"
      />
        <!-- capture="camera" -->
      <span>画像を選択</span>
    </label>
  </template>
  <template id="remove_btn">
    <span class="remove"><i class="fas fa-times"></i></span>
  </template>
  <template id="item_doc_Template">
    <div class="attached_wrap new_item">
      <div class="attached_name_btn">
        <input
          name="rel_doc_title[]"
          type="text"
          class="fileName form-control"
          required
          placeholder="例：今月のスケジュールについて"
        />
      </div>
      <div class="attached_fire_btn">
        <label>
          <input
            type="file"
            name="rel_doc[]"
            class="input-file form-control"
            accept=".pdf"
          />
          <span class="file_name">添付ファイル</span>
        </label>
      </div>
      <div class="attached_action_btn">
        <div class="add_task_link task_link_btn">
          <i class="fas fa-plus"></i>
        </div>
        <div class="delete_task_link task_link_btn" tabindex="-1">
          <i class="fas fa-minus"></i>
        </div>
      </div>
      <div class="fileDisplayName"></div>
    </div>
  </template>
</template>

<script>
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import message from "@/apis/Message";
import user from "@/apis/User";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      taglists: "",
      messages: [],
      cretemessage: {
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "board",
        content_type: "HTML",
        thanks_type: [],
        status: "公開",
        public: "公開",
        attached_file_name: [],
      },
      attached_file: [],
      images_file: [],
      loader: false,
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      editor: ClassicEditor,
      editorConfig: {},
      editorValidation: true,
      processing: false,
      imgUrl: "",
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
    $(function () {
      $(".delete_task_link")
        .data("target", "1")
        .on("click", function () {
          $(this).closest(".attached_name_btn").children(".file_name").empty();
          $(this)
            .closest(".attached_action_btn")
            .prev(".attached_fire_btn")
            .find(".input-file.form-control")
            .val("");
          $(this)
            .closest(".attached_action_btn")
            .prev(".attached_fire_btn")
            .find(".file_name")
            .text("添付ファイル");
          $(this)
            .parents(".attached_wrap")
            .children(".attached_name_btn")
            .find(".fileName.form-control")
            .val("");
          var $itembox1 = $(this).closest(".attached_wrap,.new_item");
          var htmlString = $("#item_doc_Template")
            .html()
            .children(".attached_action_btn")
            .find(".delete_task_link")
            .addClass("a")
            .attr("data-target", "1");
          $itembox1.after(htmlString);

          // var $el = $(e.currentTarget).not('[data-target="1"]');
          var $row = $(this).closest(".attached_wrap,.new_item");
          $row.remove();
        });
    });
  },
  created() {
    this.message_type = "board";
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.getUserLists();
  },
  mounted() {
    let vueThis = this;
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(document).ready(function () {
        var not = 0;
        $(document).on("change", ".post_img-file", function (e) {
          not++;
          var num = "num" + not;
          var eventDOM = event.target;
          eventDOM.parentNode.classList.add(num);
          eventDOM.parentNode.classList.add("check_img");
          eventDOM.parentNode.style.display = "none";

          if (!this.files.length) {
            return;
          }

          var $files = $(this).prop("files");
          var len = $files.length;
          for (var i = 0; i < len; i++) {
            var file = $files[i];
            var fr = new FileReader();

            fr.onload = function (e) {
              let img_div = document.createElement("div");
              img_div.classList.add("post_img_wrap");
              img_div.id = num;
              var src = e.target.result;
              let img = document.createElement("img");
              var template = document.getElementById("remove_btn");
              var clone = template.content.cloneNode(true);
              img.src = src;
              img_div.append(img);
              img_div.appendChild(clone);
              $("#preview").append(img_div);
            };

            fr.readAsDataURL(file);
          }

          $("#preview").css("display", "block");

          var $postarea = document.getElementById("post_img_area");
          var postImgBtn = document.getElementById("post_img_btn");
          var htmlString = postImgBtn.content.cloneNode(true);
          console.log($postarea);
          $postarea.append(htmlString);
        });
        $(document).on("click", ".remove", function (e) {
          var $el = $(e.currentTarget);
          var $row = $el.closest("div");
          var $target_id = $row.attr("id");
          $row.remove();
          $("#post_img_area label").each(function () {
            if ($(this).hasClass($target_id)) {
              $(this).remove();
            }
          });
          return false;
        });
      });

      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    console.log(this.$User);
    $(function () {
      $(".target_user_select").select2({
        width: "260px",
        language: {
          noResults: function () {
            return "登録されていません";
          },
        },
        placeholder: "宛先のユーザーを選択",
        allowClear: true,
        multiple: true,
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      var $filesSelectName = $el
        .closest(".attached_wrap,.new_item")
        .find(".file_name");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el
        .closest(".attached_wrap,.new_item")
        .find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#item_doc_Template").html();
      $itembox.after(htmlString);
    });

    $(document).on("click", ".delete_task_link", function (e) {
      // 最初のマイナスボタンはクリアの動き
      var $el = $(e.currentTarget).not('[data-target="1"]');
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      console.log(vueThis.attached_file);

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        console.log(vueThis.attached_file[i]);
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
  },
  methods: {
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    isRequired(value, name) {
      if (value) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    isRequiredArr(value, name) {
      if (value.length != 0) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];
      return field_name + "は必須です";
    },
    enable: function () {
      this.processing = false;
    },
    register() {
      this.processing = true;
      setTimeout(this.enable, 3000);
      if (!this.messagecolumn["title"]) {
        this.cretemessage.title = this.messagecolumn["title_str"];
      }
      if (this.messagecolumn["attached_file"]) {
        for (var i = 0; i < $("#attached_area .attached_wrap").length; i++) {
          let $el = $(".attached_wrap:eq(" + i + ")");
          let $fileName = $el.find(".fileName");
          let $fileData = $el.find(".input-file");

          if (!($fileName.val() == "" && $fileData[0].files.length == 0)) {
            if ($fileName.val() == "") {
              alert("資料名を入力してください");
              this.attached_file = [];
              return;
            }
            if ($fileData[0].files.length == 0) {
              alert("ファイルを選択してください");
              this.attached_file = [];
              return;
            }
            if (file && permit_type.indexOf(file.type) == -1) {
              alert("アップロードできるファイルの形式はpdfです");
              this.attached_file = [];
              return;
            }

            let fileObject = new FormData();

            fileObject.append("key", "documents");
            fileObject.append("display_name", $fileName.val());
            fileObject.append("uploadfile", $fileData[0].files[0]);

            this.attached_file.push(fileObject);

            var file = $fileData[0].files[0];
            var permit_type = ["application/pdf"];
          }
        }
      }
      if (this.messagecolumn["photolog"]) {
        for (i = 0; i < $("#post_img_area .check_img").length; i++) {
          let $el = $(".check_img:eq(" + i + ")");
          let $fileData = $el.find(".input-file");

          let fileObject = new FormData();

          fileObject.append("key", "post_images");
          fileObject.append("display_name", "掲示板の投稿画像");
          fileObject.append("uploadfile", $fileData[0].files[0]);
          this.images_file.push(fileObject);
        }
      }
      $("#js-loader").fadeIn();
      message
        .register(this.cretemessage)
        .then(
          (response) => {
            console.log(response);
            if (this.cretemessage.photolog) {
              this.postMessagefile(
                "photolog",
                "photo_log",
                response.data.data.id
              );
            }
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            if (this.images_file.length > 0) {
              this.postMessageImagesfile(response.data.data.id);
            }
            $("#js-loader").fadeOut();
            this.$router.push({
              name: "Front board show",
              params: { type: "board", id: response.data.data.id },
            });
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },

    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        await message
          .register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },

    async postMessageImagesfile(createdId) {
      for (var i = 0; i < this.images_file.length; i++) {
        await message
          .register_file(this.images_file[i], createdId)
          .then(
            (response) => {
              if (i == this.images_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },

    getUserLists() {
      user
        .list()
        .then((response) => {
          if (response != null) {
            this.userlists = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    postMessagefile(key, display_name, id) {
      let fileObject = new FormData();

      fileObject.append("key", key);
      fileObject.append("display_name", display_name);
      fileObject.append("uploadfile", this.cretemessage.photolog[0]);

      console.log(fileObject);
      message
        .register_file(fileObject, id)
        .then(
          (response) => {
            console.log("file");
            console.log(response);
          },
          (error) => {
            console.log("fileerror");
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("fileerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>